import React, { useState, useCallback, useEffect, useMemo } from 'react';

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//#TODO EXPRESS STATIC IMAG PAR LE SERVEUR

import { 
  Button, Form, FormControl,
  Navbar , Nav, NavDropdown,
  Card
} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import MyEmail from '../../tools/email';
import { renderEmail } from 'react-html-email';

//import  '../../assets/css/bootstrap.min.css';
//import  '../../assets/css/bootstrap-theme.min.css';
import  '../../assets/css/fontAwesome.css';
//import  '../../assets/css/hero-slider.css';
import  '../../assets/css/owl-carousel.css';
import '../../assets/css/templatemo-style.css';
import './style.css';
import  '../../assets/css/lightbox.css';


import bgImage from '../../assets/img/shuttlecock-21263_1920.webp';
import caroussel1 from '../../assets/img/carousel/1.webp';
import caroussel2 from '../../assets/img/carousel/2.webp';
import caroussel3 from '../../assets/img/carousel/3.webp';
import PdfInscription from '../../assets/ressources/inscriptions.pdf';

import service_icon_01 from '../../assets/img/service_icon_01.webp';
import service_icon_02 from '../../assets/img/service_icon_02.webp';
import service_icon_03 from '../../assets/img/service_icon_03.webp';
import service_icon_04 from '../../assets/img/service_icon_04.webp';

//class MainPage extends React.Component{
const MainPage = () =>{
  const [mailName, setMailName] = useState('');
  const [mail, setMail] = useState('');
  const [body, setBody] = useState('');
  const [navCollapse, setNavCollapse] = useState();
  const [isTop, setIsTop] = useState(true);
  const [carouselVisible, setCarouselVisible] = useState(false);
  const [emailModalvisible, setEmailModalvisible] = useState(false);
  

  const scrollToTop = () => {
    scroll.scrollToTop();
  }

  const scrollToAbout = () => {
    scroller.scrollTo('about', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80,
    })
  }

  const scrollToPortFolio = () => {
    scroller.scrollTo('portfolio', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80,
    });
  }

  const scrollToBlog = () => {
    scroller.scrollTo('blog', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80,
    })
  }

  const scrollToContactUs = () => {
    scroller.scrollTo('contact-us', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -80,
    })
  }

  const showCarousel = () => {
    setCarouselVisible(true);
  }

  const hideCarousel = () => {
    setCarouselVisible(false);
  }
  
  const showEmailModal = () => {
    setEmailModalvisible(true);
  }

  const hideEmailModal = () => {
    setEmailModalvisible(false);
  }

  const sendEmail = (event) =>{
    const messageHtml =  renderEmail(
      <MyEmail name={mailName}> {body}</MyEmail>
    );
    axios({
      method: "POST", 
      url:"/sendmail", 
      data: {
        name: mailName,
        email: mail,
        messageHtml: messageHtml
      }
    }).then((response)=>{
      if (response.data.msg === 'success'){
        setMailName('');
        setMail('');
        setBody('');    
        setEmailModalvisible(false);
      }else if(response.data.msg === 'fail'){
          alert("Oups, nous avons rencontré un problème. Veuillez réessayer.")
      }
    })
   }

  const handleChangeName = (event)=>{
    setMailName(event.target.value);
  }

  const handleChangeEmail = (event)=>{
    setMail(event.target.value);
  }
  const handleChangeBody = (event)=>{
    setBody(event.target.value);
  }
  
  useEffect(()=>{
    document.addEventListener("scroll", () => {
      const _isTop = window.scrollY < 100;
      if (_isTop !== isTop) {
        setIsTop(_isTop);
      }
    });  
  })

    return (
      <div id="mainpage">
        <div className={isTop ? "header headeristop" : "header headernotistop"}>
          <div className="container">
            {/* */}
            <Navbar className="navbar navbar-inverse">
              <Navbar.Brand className="navbar-header">
                {/*<button
                  type="button"
                  id="nav-toggle"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#main-nav"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                */}
                <a
                  className={
                    isTop
                      ? "navbar-brand scroll-top"
                      : "navbar-brand-nottop scroll-top"
                  }
                  onClick={scrollToTop}
                >
                  <em>T</em>réméoc <em>B</em>adminton <em>L</em>oisir
                </a>
              </Navbar.Brand>
              <Navbar.Toggle
                id="nav-toggle"
                className="navbar-toggle"
                data-toggle="collapse"
              ></Navbar.Toggle>
              <Navbar.Collapse
                id="main-nav"
                className="collapse navbar-collapse"
              >
                <Nav className="nav navbar-nav">
                  <Nav.Link
                    className={
                      isTop ? "navli navli-color" : "navli navli-color-nottop"
                    }
                    onClick={scrollToTop}
                  >
                    <a>Accueil</a>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      isTop ? "navli navli-color" : "navli navli-color-nottop"
                    }
                    onClick={scrollToAbout}
                  >
                    <a>L'association</a>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      isTop ? "navli navli-color" : "navli navli-color-nottop"
                    }
                    onClick={scrollToPortFolio}
                  >
                    <a>Portfolio</a>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      isTop ? "navli navli-color" : "navli navli-color-nottop"
                    }
                    onClick={scrollToBlog}
                  >
                    <a>Blog</a>
                  </Nav.Link>
                  <Nav.Link
                    className={
                      isTop ? "navli navli-color" : "navli navli-color-nottop"
                    }
                    onClick={scrollToContactUs}
                  >
                    <a>Nous contacter</a>
                  </Nav.Link>
                  <Nav.Link
                    className={isTop ? "navli" : "navli navli-color-nottop"}
                    href={PdfInscription}
                    download="inscriptionTremeoc2019.pdf"
                  >
                    <a>Inscription</a>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div className="parallax-content baner-content" id="home">
          <div className="container">
            <div className="text-content">
              <h2>
                <em>Tréméoc</em> <span>Badminton</span> Loisir
              </h2>
              <p>
                Sport tonique et ludique par excellence ,<br /> les débutants
                s'amusent dès le 1er entraînement !
              </p>
              <div className="primary-white-button" href="#home">
                <a onClick={scrollToAbout}>Bonne Visite</a>
              </div>
            </div>
          </div>
        </div>
        <section id="about" className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-n6 col-xs-12">
                <div className="service-item">
                  <div className="icon">
                    <img src={service_icon_01} alt=""></img>
                  </div>
                  <h4>Les entrainements</h4>
                  <div className="line-dec"></div>
                  <p>
                    2 séances principales :
                    <br />
                    - le mardi de 19h à 21h
                    <br />
                    - le vendredi de 19h à 21h
                    <br />
                    <br />
                    Pour les enfants :
                    <br />
                    - le vendredi de 18h à 19h
                    <br />
                    <br />
                    - le dimanche de 10h à 12h :
                    <br />
                    selon disponibilité de la salle
                    <br />
                    et d'un encadrant.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="service-item">
                  <div className="icon">
                    <img src={service_icon_03} alt=""></img>
                  </div>
                  <h4>Evènements</h4>
                  <div className="line-dec"></div>
                  <p>
                    Tout au long de la saison, différents
                    <br />
                    évènements sont organisés :
                    <br />
                    <br />
                    - tournoi du Téléthon
                    <br />
                    - rencontre inter-association
                    <br />- pot de fin de saison...
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="service-item">
                  <div className="icon">
                    <img src={service_icon_04} alt=""></img>
                  </div>
                  <h4>Règlementation</h4>
                  <div className="line-dec"></div>
                  <p>
                    Il est demandé aux adhérents
                    <br />
                    de prendre soin des équipements,
                    <br />
                    de participer aux montages/démontages des terrains,
                    <br />
                    de rendre la salle propre.
                  </p>
                </div>
              </div>

              <div className="primary-blue-button col-md-3 col-sm-6 col-xs-12 offset-5">
                <a className="scroll-link" onClick={scrollToPortFolio}>
                  Continuer...
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio">
          <div className="content-wrapper">
            <div className="inner-container container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="section-heading">
                    <h4>Les photos</h4>

                    <div className="line-dec">
                      <br />
                      <p>Quelques prises de vues....</p>
                      <div className="filter-categories">
                        <ul className="project-filter">
                          <li className="filter" data-filter="all">
                            <span></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 mt-2">
                  <div className="projects-holder">
                    <div className="col-md-6 col-sm-6">
                      <Image
                        src={caroussel1}
                        thumbnail
                        onClick={showCarousel}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <Image
                        src={caroussel2}
                        thumbnail
                        onClick={showCarousel}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <Image
                        src={caroussel3}
                        thumbnail
                        onClick={showCarousel}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="testimonial">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-md-offset-3">
              <div class = "carousel slide" data-ride = "carousel" id = "quote-carousel">
 
 {/* Carousel Slides / Quotes */}
 {/*
 <div class = "carousel-inner text-center">

     
     <div class = "item active">
         <blockquote>
             <div class = "row">
                 <div class = "col-sm-8 col-sm-offset-2">
                     <p> Edureka has the best online courses! </p>
                     <small> Reviewer's Name </small>
                 </div>
             </div>
         </blockquote>
     </div>
     
     <div class = "item">
         <blockquote>
             <div class = "row">
                 <div class = "col-sm-8 col-sm-offset-2">
                     <p> Edureka has the best online courses!  </p>
                     <small> Reviewer's Name </small>
                 </div>
             </div>
         </blockquote>
     </div>
     
     <div class = "item">
         <blockquote>
             <div class = "row">
                 <div class = "col-sm-8 col-sm-offset-2">
                     <p> Edureka has the best online courses! </p>
                     <small> Someone famous </small>
                 </div>
             </div>
         </blockquote>
     </div>
 </div>
 */}
 {/* Bottom Carousel Indicators */}
 {/*<ol class = "carousel-indicators">
 <li data-target = "#quote-carousel" data-slide-to = "0" class = "active"> 
  <img class = "img-responsive " src = '<a href='https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg'>https://s3.amazonaws.com/uifaces/faces/twitter/mantia/128.jpg</a>" alt="">
 </li>
  <li data-target = "#quote-carousel" data-slide-to= "1"> <img class = "img-responsive" src = "<a href="https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg">https://s3.amazonaws.com/uifaces/faces/twitter/adhamdannaway/128.jpg</a>" alt = "">
  </li>
  <li data-target = "#quote-carousel" data-slide-to = "2"><img class = "img-responsive" src = "<a href="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg">https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg</a>" alt="">
  </li>
 </ol>
                  */}
{/* Carousel Buttons Next/Prev  */}
{/*
 <a data-slide = "prev" href = "#quote-carousel" class = "left carousel-control"><i class = "fa fa-chevron-left"> </i> </a>
 <a data-slide = "next" href = "#quote-carousel" class = "right carousel-control"><i class = "fa fa-chevron-right"></i></a>
*/}
 </div>


              </div>
            </div>
          </div>
        </section>

        <div className="tabs-content" id="blog">
          <div className="container">
            <div className="row">
              <div className="wrapper">
                <div className="col-md-8">
                  <div className="section-heading">
                    <h4>Derniers artcicles</h4>
                    <div className="line-dec"></div>
                    <p>
                      Actualités, liens, vidéos <br />
                      et autres contenus
                      <br /> ci-dessous
                    </p>
                    <ul
                      className="tabs clearfix"
                      data-tabgroup="first-tab-group"
                    >
                      <li>
                        <a className="active">bientôt disponible</a>
                      </li>
                      {/*  <li>
                        <a href="#tab2">Quisque ultricies maximus</a>
                      </li>
                      <li>
                        <a href="#tab3">Sed vel elit et lorem</a>
                      </li>
                      <li>
                        <a href="#tab4">Vivamus purus neque</a>
                      </li>
                */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <section id="first-tab-group" className="tabgroup">
                    {/*  <div id="tab1">
                      <img src="../../assets/img/blog_item_01.jpg" alt=""></img>
                      <div className="text-content">
                        <h4>Bientôt disponible</h4>
                        <span>
                         <a href="#">Digital Marketing</a> /{" "}
                          <a href="#">Honey</a> /{" "}
                          <a href="#">21 September 2020</a>
              
                        </span>
                        <p>
                         
                        </p>
                      </div>
                    </div>
                    <div id="tab2">
                      <img src="../../assets/img/blog_item_02.jpg" alt=""></img>
                      <div className="text-content">
                        <h4>Bientôt disponible</h4>
                        <span>
                          <a href="#"></a>  <a href="#"></a> {" "}
                          <a href="#"></a>
                        </span>
                        <p>
                         
                        </p>
                      </div>
                    </div>
                    <div id="tab3">
                      <img src="../../assets/img/blog_item_03.jpg" alt=""></img>
                      <div className="text-content">
                        <h4>Bientôt disponible</h4>
                        <span>
                          <a href="#"></a>  <a href="#"></a> {" "}
                          <a href="#"></a>
                        </span>
                        <p>
                        
                        </p>
                      </div>
                    </div>
                    <div id="tab4">
                      <img src="../../assets/img/blog_item_04.jpg" alt=""></img>
                      <div className="text-content">
                        <h4>Bientôt disponible</h4>
                        <span>
                          <a href="#"></a>  <a href="#"></a> {" "}
                          <a href="#"></a>
                        </span>
                        <p>
                         
                        </p>
                      </div>
            </div>*/}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="contact-us">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-heading">
                  <h4>Nous contacter</h4>
                  <div className="line-dec"></div>
                  <p>
                    Besoin d'informations, de renseignements,
                    <br />
                    n'hésitez pas à nous écrire.
                  </p>
                  <div className="pop-button" onClick={showEmailModal}>
                    <h4>Email</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="map">
          {/*<!-- How to change your own map point
            1. Go to Google Maps
            2. Click on your location point
            3. Click "Share" and choose "Embed map" tab
            4. Copy only URL and paste it within the src="" field below
      -->*/}
          <iframe
            src="https://maps.google.com/maps?q=47.9081162,-4.2078505&t=&z=17&ie=UTF8&iwloc=&output=embed"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen
          />
        </div>

        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="logo">
                  <a className="logo-ft scroll-top" onClick={scrollToTop}>
                    <em>T</em>B<em>L</em>
                  </a>
                  <p>
                    Copyright &copy; 2020 FLB
                    <br />
                    contact@badmintonloisir.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Modal
          size="lg"
          show={carouselVisible}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal"
          onHide={hideCarousel}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Quelques photos
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Carousel>
              <div>
                <img src={caroussel1} />
              </div>
              <div>
                <img src={caroussel2} />
              </div>
              <div>
                <img src={caroussel3} />
              </div>
            </Carousel>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={hideCarousel}>
              Fermer
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={emailModalvisible}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal"
          onHide={hideEmailModal}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Nous envoyer un email
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="contact" action="#" method="post">
              <div className="row">
                <div className="col-md-12">
                  <fieldset>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Votre nom..."
                      required=""
                      onChange={handleChangeName}
                    ></input>
                  </fieldset>
                </div>
                <div className="col-md-12">
                  <fieldset>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Votre email..."
                      required=""
                      onChange={handleChangeEmail}
                    ></input>
                  </fieldset>
                </div>
                <div className="col-md-12">
                  <fieldset>
                    <textarea
                      name="message"
                      rows="6"
                      className="form-control"
                      id="message"
                      placeholder="Votre message..."
                      required=""
                      onChange={handleChangeBody}
                    ></textarea>
                  </fieldset>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={sendEmail}>
              Envoyer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
    }
  

export default MainPage;