import React from 'react';

import './App.css'

import MainPage from './pages/mainpage';

function App() {
  return (
      <MainPage/>
  );
}

export default App;
